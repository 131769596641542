import React from 'react'
import './OurTeamSection.css'
import H3Title from '../../components/H3Tittle/H3Title'
import TeamMember from '../../components/TeamMember/TeamMember'
import RoundButton from '../../components/RoundButton/RoundButton'

const OurTeamSection = ({ isHomeColor, colorSecondary }) => {
  return (
    <div className="container position-relative">
      <div className="row">
        <div className="col-12">
          <H3Title isHomeColor={isHomeColor} colorSecondary={colorSecondary} text="Our Team" />
        </div>
      </div>
      <TeamMember isHomeColor={isHomeColor} colorSecondary={colorSecondary}>
        <h4>Adrien Loesch</h4>
        <p>
          A London-trained finance and tech professional, Adrien relishes the idea of learning new
          things and constantly growing his network. LuxUnplugged has given him the opportunity to
          achieve both whilst relentlessly promoting his native country at the international level.
        </p>
        <div className="member-info-footer">
          <RoundButton
            ariaLabel={'LinkedIn of Adrien Loesch'}
            className="mx-2"
            href="https://www.linkedin.com/in/adrienloesch/"
            icon="LinkedInIcon"
          />
        </div>
      </TeamMember>
      <TeamMember
        isHomeColor={isHomeColor}
        colorSecondary={colorSecondary}
        circleOrder="1"
        imgPerson={'Thierry'}
        imgAlt={'Thierry Schoen'}
        imgTop={'-50px'}
        imgLeft={'-80px'}
        imgTransform={'scale(0.8)'}
        circlesColor={'var(--lilac-space)'}
        circlesRotation={'0deg'}
        backgroundColor={'rgba(var(--lilac-space-code), 0.2)'}
        lineDirection={'50%'}
        className={'Thierry'}
      >
        <h4>Thierry Schoen</h4>
        <p>
          Based in London since 2008, Thierry has worked in senior sales roles both for listed and
          PE backed businesses and enjoys connecting and working across multiple industries.
          LuxUnplugged allowed Thierry to connect with leaders in Luxembourg and promote the
          country’s companies internationally.
        </p>
        <div className="member-info-footer">
          <RoundButton
            ariaLabel={'LinkedIn of Thierry Schoen'}
            className="mx-2"
            href="https://www.linkedin.com/in/thierrylxb/"
            icon="LinkedInIcon"
          />
        </div>
      </TeamMember>
      <TeamMember
        isHomeColor={isHomeColor}
        colorSecondary={colorSecondary}
        circleOrder="0"
        imgPerson={'Elena'}
        imgAlt={'Elena Sychkova'}
        imgTop={'-20px'}
        imgLeft={'25px'}
        imgTransform={'scale(0.8)'}
        circlesColor={'var(--lime-net-zero)'}
        circlesRotation={'90deg'}
        backgroundColor={'rgba(var(--lime-net-zero-code), 0.2)'}
        lineDirection={'-50%'}
      >
        <h4>Elena Sychkova</h4>
        <p>
          Based in Luxembourg since 2015, Elena specializes in social media and digital marketing,
          with diverse experience in both fintech start-ups and corporate environments. ‘The
          LuxUnplugged podcast allows me to contribute to a project that showcases Luxembourg’s
          vibrant business ecosystem on both local and international stages.’ — says Elena.
        </p>
        <div className="member-info-footer">
          <RoundButton
            ariaLabel={'LinkedIn of Elena Sychkova'}
            className="mx-2"
            href="https://www.linkedin.com/in/elena-sychkova/"
            icon="LinkedInIcon"
          />
        </div>
      </TeamMember>
      {/* <TeamMember
        isHomeColor={isHomeColor}
        colorSecondary={colorSecondary}
        circleOrder="1"
        imgPerson={'Kirill'}
        imgAlt={'Kirill Mitsurov'}
        imgTop={'15px'}
        imgLeft={'60px'}
        imgTransform={'scale(0.9)'}
        circlesColor={'var(--violet-business)'}
        circlesRotation={'0deg'}
        backgroundColor={'rgba(var(--violet-business-code), 0.2)'}
        lineDirection={'50%'}
        className={'Kirill'}
      >
        <h4>Kirill Mitsurov</h4>
        <p>
          Based in Luxembourg since 2014, Kirill has over 20 years of experience in graphics, UI/UX,
          and motion design. He joined LuxUnplugged to create a new design concept for the podcast.
          The collaboration became a great platform for promoting his skills and his company,
          "8points" - Motion Design Studio.
        </p>
        <div className="member-info-footer">
          <RoundButton
            ariaLabel={'LinkedIn of Kirill Mitsurov'}
            className="mx-2"
            href="https://www.linkedin.com/in/kirill-mitsurov/"
            icon="LinkedInIcon"
          />
        </div>
      </TeamMember> */}
    </div>
  )
}

export default OurTeamSection
