import { useLocation } from 'react-router-dom'
import './HeroPersons.css'
import CirclesCroppedGroup from '../_SVG/CirclesCroppedGroup'

const HeroPersons = ({
  isHome = false,
  colorPrimary,
  episodeImageSrc = null,
  episodeImageAlt = null,
}) => {
  let location = useLocation()

  let locationArray = location.pathname.split('/')

  // console.log('location', location)
  return (
    <div className="HeroPersons">
      {location.pathname === '/' || location.pathname === '/net-zero-future-podcast' ? (
        <>
          <img
            width="433px"
            height="686px"
            className="HeroPersonsImg Adrien animation-right"
            src="/img/Adrien.png"
            alt="Adrien Loesch"
          />
          <img
            width="501px"
            height="820px"
            className="HeroPersonsImg Thierry animation-left"
            src="./img/Thierry.png"
            alt="Thierry Schoen"
          />
        </>
      ) : null}
      {location.pathname === '/business-podcast' ||
      location.pathname === '/dark-matter-uncovered-podcast' ? (
        <>
          <img
            width="501px"
            height="820px"
            className="HeroPersonsImg Thierry animation-left"
            src="/img/Thierry.png"
            alt="Thierry Schoen"
          />
          <img
            width="433px"
            height="686px"
            className="HeroPersonsImg Adrien animation-right"
            src="/img/Adrien.png"
            alt="Adrien Loesch"
          />
        </>
      ) : null}
      {location.pathname === '/business-podcast/episode/person' ? (
        <div className="episode-img-wrapper">
          <img
            height="600px"
            width="600px"
            className="episode Thierry animation-left"
            src="/img/cover/Business/Georges_Krombach.jpg"
            alt="Georges KROMBACH"
          />
          <div className="overlay" style={{ backgroundColor: colorPrimary }}></div>
          <span
            style={{
              zIndex: '5',
              position: 'absolute',
              top: '52px',
              left: '52px',
              width: '580px',
              height: '580px',
            }}
          >
            <CirclesCroppedGroup color={colorPrimary} width="572" />
          </span>
        </div>
      ) : null}
      {locationArray[1] == 'episode' && episodeImageSrc.length > 1 ? (
        <div className="episode-img-wrapper">
          <img
            height="600px"
            width="600px"
            className="episode Thierry animation-left"
            src={episodeImageSrc}
            alt={episodeImageAlt}
          />
          <div className="overlay" style={{ backgroundColor: colorPrimary }}></div>
          <span
            style={{
              zIndex: '5',
              position: 'absolute',
              top: '52px',
              left: '52px',
              width: '580px',
              height: '580px',
            }}
          >
            <CirclesCroppedGroup color={colorPrimary} width="572" />
          </span>
        </div>
      ) : null}
      {location.pathname === '/about' ? (
        <>
          {/* <img
            width="261px"
            height="486px"
            style={{ left: '-20px', top: '130px', scale: '1.1', rotate: '3deg' }}
            className="HeroPersonsImg Kirill About animation-left"
            src="/img/Kirill.png"
            alt="Kirill Mitsurov"
          /> */}
          <img
            width="298px"
            height="543px"
            style={{ left: '430px', top: '160px', scale: '1.05' }}
            className="HeroPersonsImg Elena About animation-right"
            src="/img/Elena.png"
            alt="Elena Sychkova"
          />
          <img
            width="433px"
            height="686px"
            style={{ left: '200px', top: '77px' }}
            className="HeroPersonsImg Adrien About animation-right"
            src="/img/Adrien.png"
            alt="Adrien Loesch"
          />
          <img
            width="501px"
            height="820px"
            style={{ left: '-10px', top: '8px', scale: '0.95' }}
            className="HeroPersonsImg Thierry About animation-left"
            src="/img/Thierry.png"
            alt="Thierry Schoen"
          />
        </>
      ) : null}
    </div>
  )
}

export default HeroPersons
