import React from 'react'
import './PodcastDescriptionSection.css'
import { Link, useLocation } from 'react-router-dom'
import SoundwaveLine from '../../components/_SVG/SoundwaveLine'
import { images_list_by_id } from '../../images'

const PodcastLeft = ({ description, title }) => {
  return (
    <>
      <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
      <div className="description">
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </>
  )
}

const PodcastRight = ({ colorSecondary = '', guest, hosts = 'Thierry & Adrien', id }) => {
  let linksobj = {}
  if (id != null && images_list_by_id[id] != undefined) {
    linksobj = images_list_by_id[id].links
  }
  let linksArray = []
  Object.keys(linksobj).forEach((key, index) => {
    let t = {
      title: key,
      linkhref: linksobj[key],
    }
    linksArray.push(t)
  })
  return (
    <>
      {linksArray.length > 0 ? (
        <>
          <div className="podcast-links mb-4">
            <ul>
              <li>EPISODE LINKS</li>
              {linksArray.map((val, ind) => {
                return (
                  <li key={ind}>
                    <Link target="_blank" to={val.linkhref}>
                      <span>{val.title}</span>
                      <SoundwaveLine color={colorSecondary} className="ms-2" lineWidth={20} />
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <hr />
        </>
      ) : null}
      <div className={`podcast-persons ${linksArray.length > 0 ? `mt-5` : null}`}>
        <ul>
          <li className="mt-0">HOSTED & PRODUCED BY</li>
          <li>
            <Link to="/about">Thierry & Adrien</Link>
          </li>
          {/* <li>DESIGN SUPPORT BY</li>
          <li>
            <Link to="/about">Kirill Mitsurov</Link>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export { PodcastLeft, PodcastRight }
